import React, { useState } from 'react'
import emailjs from 'emailjs-com'
import { Spinner, Modal, Button } from 'react-bootstrap'
import ReCAPTCHA from 'react-google-recaptcha'

const ContactScreen = () => {
  const [Email, setEmail] = useState('')
  const [Message, setMessage] = useState('')
  const [Loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [isVerified, setisVerified] = useState(false)
  const [ishumanError, sethumanError] = useState(false)
  const [show, setShow] = useState(false)
  const {
    REACT_APP_SITE_KEY,
    REACT_APP_TEMP_KEY,
    REACT_APP_SERVICE_ID,
    REACT_APP_USER_ID,
  } = process.env
  const handleClose = () => setShow(false)
  const emailValidation = () => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(Email)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const valid = emailValidation()
    if (valid) {
      if (isVerified) {
        setLoading(true)
        emailjs
          .sendForm(
            REACT_APP_SERVICE_ID,
            REACT_APP_TEMP_KEY,
            e.target,
            REACT_APP_USER_ID
          )
          .then(
            (result) => {
              setLoading(false)
              sethumanError(false)
              setisVerified(false)
              setShow(true)
            },
            (error) => {
              setLoading(false)
              sethumanError(false)
              setisVerified(false)
            }
          )

        e.target.reset()
      } else {
        sethumanError(true)
      }
    } else {
      setError(true)
    }
  }
  function onChange(value) {
    if (value) {
      setisVerified(true)
      ishumanError(false)
    } else {
      sethumanError(true)
    }
  }

  return (
    <>
      {Loading ? (
        <div
          className='m-auto py-5'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '700px',
          }}
        >
          <Spinner
            animation='border'
            role='status'
            style={{ width: 70, height: 70 }}
          >
            <span className='sr-only'>Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <Modal show={show} onHide={handleClose} animation={false} centered>
            <Modal.Header style={{ backgroundColor: '#2ead7c', height: 50 }}>
              <Modal.Title style={{ color: 'white', fontSize: 22 }}>
                <i className='far fa-check-circle'></i> Success!
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>Message sent successfully!</Modal.Body>
            <Modal.Footer>
              <Button variant='success' onClick={handleClose}>
                OK
              </Button>
            </Modal.Footer>
          </Modal>
          <form onSubmit={handleSubmit}>
            <div className='m-auto py-5' style={{ width: '60%' }}>
              <h2>Contact</h2>
              <p style={{ fontSize: 16, fontWeight: 'lighter' }}>
                We are a small company and we value customers feedback and
                suggestions in order for us to serve you better. Feel free to
                drop us any question, comment, or suggestions below.{' '}
              </p>
              <div className='form-group'>
                <label htmlFor='exampleInputEmail1' className='form-label mt-4'>
                  Email address
                </label>
                <input
                  required
                  type='email'
                  name='email'
                  className={error ? 'form-control is-invalid' : 'form-control'}
                  id='exampleInputEmail1'
                  aria-describedby='emailHelp'
                  placeholder='Email'
                  onChange={(e) => setEmail(e.target.value)}
                ></input>
                <div className='invalid-feedback'>Invalid Email Format.</div>
                <div className='form-group'>
                  <label htmlFor='Message' className='form-label mt-4'>
                    Message
                  </label>
                  <textarea
                    required
                    name='message'
                    className='form-control'
                    id='exampleTextarea'
                    placeholder='Type a message'
                    rows='5'
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
                <div
                  className='my-4'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <ReCAPTCHA sitekey={REACT_APP_SITE_KEY} onChange={onChange} />

                  {ishumanError ? (
                    <div className='feedback-invalid mx-3 text-danger'>
                      ReCAPTCHA check failed. Please try again.
                    </div>
                  ) : null}
                </div>
                <div className='form-group'></div>
                <div
                  className='my-3'
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <button type='submit' className='btn btn-primary'>
                    Send
                  </button>
                </div>
              </div>
            </div>
          </form>
        </>
      )}
    </>
  )
}

export default ContactScreen
