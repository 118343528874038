import React from 'react'
import { Card, Dropdown, DropdownButton } from 'react-bootstrap'

const DomainCard = (props) => {
  const { domain, avail } = props

  const link = `https://sedo.com/checkdomainoffer.php?language=us&domain=${domain}&language=us&campaignId=327419`
  return (
    <Card className={avail ? 'card-domain-tr' : 'card-domain-fl'}>
      <Card.Body
        className='flexbox-container'
        onClick={() => {
          console.log('CG')
        }}
      >
        {avail ? (
          <i
            className='fas fa-check-circle'
            style={{ paddingRight: 10, color: '#6dcf33' }}
          ></i>
        ) : (
          <i
            className='fas fa-times-circle'
            style={{ paddingRight: 10, color: '#ec014e' }}
          ></i>
        )}
        <Card.Text
          style={{
            alignItems: 'center',
            width: '78%',
            paddingTop: 15,
            cursor: 'pointer',
          }}
          onClick={() => {
            window.open(
              'https://www.bluehost.com/track/merly/bluehostmerly',
              '_blank'
            )
          }}
        >
          {domain}
        </Card.Text>
        <DropdownButton
          className='dropdownbutton'
          style={{ alignItems: 'flex-end' }}
          id='dropdown-basic-button'
          title='buy'
          size='sm'
          variant='success'
          disabled={!avail ? true : false}
        >
          <Dropdown.Item
            href='https://www.bluehost.com/track/merly/bluehostmerly'
            target='_blank'
          >
            Blue Host
          </Dropdown.Item>

          <Dropdown.Item href={link}>Sedo.com</Dropdown.Item>
        </DropdownButton>
      </Card.Body>
    </Card>
  )
}

export default DomainCard
