import React, { useState } from 'react'
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  Card,
  Form,
  Spinner,
} from 'react-bootstrap'
import { makeStyles } from '@material-ui/core/styles'
import DomainCard from '../components/cards/DomainCard'
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}))
const HomeScreen = () => {
  const classes = useStyles()
  const [show, setShow] = useState(false)
  const [domains, setdomains] = useState(null)
  const [keywords, setkeywords] = useState('')
  const [loading, setloading] = useState(false)
  const [errorMessage, seterrorMessage] = useState(null)
  const [err, seterr] = useState('Something Went Wrong.')
  const handleSubmit = (e) => {
    e.preventDefault()

    if (keywords) {
      setloading(true)
      const config = {
        method: 'POST',
        url: '/generate',
        data: {
          keywords: keywords,
        },
      }

      axios(config)
        .then((response) => {
          setdomains(response.data.domains)
          setloading(false)
          if (response.data.domain == null) {
            seterrorMessage('Valid Domain Name Not Found')
          }
        })
        .catch((error) => {
          if (error.response.data.message) {
            seterr(error.response.data.message)
          } else {
            seterr('Something Went Wrong.')
          }
          setloading(false)
          setShow(true)
        })
        .then(function () {
          setloading(false)
        })
    } else {
      seterr('Keywords missing.')
      setShow(true)
    }
  }

  return (
    <>
      {show ? (
        <div className='m-auto py-3' style={{ width: '40%' }}>
          <div
            className='alert alert-dismissible alert-danger'
            show={show.toString()}
          >
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='alert'
              onClick={() => {
                setShow(false)
              }}
            ></button>
            <strong>Oh snap!</strong> {err}
          </div>
        </div>
      ) : null}

      <Row style={{ paddingTop: '10%' }}>
        <Col className='text-center'>
          <h1>Top Domain Name Generator</h1>

          <p style={{ fontSize: 16, fontWeight: 'lighter' }}>
            Free Domain name generator for your innovative ideas.
          </p>
          <Form onSubmit={handleSubmit}>
            <InputGroup size='lg' className='m-auto py-5  '>
              <FormControl
                placeholder='Type your keywords here'
                aria-label='Type your keywords here'
                aria-describedby='basic-addon2'
                onChange={(e) => setkeywords(e.target.value)}
              />
              <InputGroup.Append>
                <Button
                  size='lg'
                  variant='secondary'
                  style={{ height: 65 }}
                  onClick={handleSubmit}
                >
                  Search
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Form>
          {domains ? (
            <div className={classes.root}>
              <h3 className='py-3'>Best Domains</h3>

              <Container fluid={false}>
                <Row>
                  {domains.map((dom) => (
                    <Col xs={12} xl={4} lg={6}>
                      <div className='mt-3'>
                        <DomainCard domain={dom.domain} avail={dom.available} />
                      </div>
                    </Col>
                  ))}
                </Row>
              </Container>
            </div>
          ) : (
            <div className={classes.root}>
              {errorMessage ? <h3 className='py-3'>{errorMessage}</h3> : null}
            </div>
          )}
          {loading ? (
            <Spinner
              animation='border'
              role='status'
              style={{ width: 50, height: 50 }}
            >
              <span className='sr-only'>Loading...</span>
            </Spinner>
          ) : (
            <Row
              className='m-auto py-4'
              style={{
                width: '80%',
              }}
            >
              <Col xs={12} md={8} lg={5} xl={4} className='py-3'>
                <Card className='card-main'>
                  <Card.Img src='block1.png' />
                </Card>
              </Col>
              <Col xs={12} md={8} lg={5} xl={4} className='py-3'>
                <Card className='card-main'>
                  <Card.Img src='block2.png' />
                </Card>
              </Col>

              <Col xs={12} md={8} lg={5} xl={4} className='py-3'>
                <Card
                  className='card-main'
                  onClick={() => {
                    window.open('https://www.bluehost.com/', '_blank')
                  }}
                >
                  <Card.Img src='block3.png' />
                </Card>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  )
}

export default HomeScreen
