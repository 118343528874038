import React from 'react'
import Footer from './components/Footer'
import Header from './components/Header'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import HomeScreen from './screens/HomeScreen'
import ContactScreen from './screens/ContactScreen'
function App() {
  return (
    <Router>
      <Header />
      <Container>
        <main>
          <Container>
            <Route path='/' component={HomeScreen} exact />
            <Route path='/contact' component={ContactScreen} exact />
          </Container>
        </main>
      </Container>
      <Footer />
    </Router>
  )
}

export default App
